import React, { useMemo } from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";
import useAuthStore from "@/stores/auth";
import WizardIcon from "@/images/icons/icon-effect.svg";

export default function SlideEditorHeader({ title, undoEnabled, onUndo, onReGenerate, className, titleClassName }) {
	const user = useAuthStore((state) => state.user);

	return (
		<div
			className={tailwindCascade(
				"flex flex-row justify-start w-full text-sm font-bold text-white transform translate-y-[1px] h-[31px]",
				className
			)}
		>
			<span
				className={tailwindCascade(
					"relative bg-petrol-dark rounded-t-md inline-block px-3 py-2 leading-none",
					titleClassName
				)}
			>
				{title}
			</span>
			<div className="flex ml-auto space-x-6">
				{/*undoEnabled && onUndo && (
					<button className="font-bold underline" type="button" onClick={onUndo}>
						{trans("Undo")}
					</button>
				)*/}
				{onReGenerate && (
					<button
						className="font-bold flex flex-row justify-start items-center space-x-0.5"
						type="button"
						onClick={onReGenerate}
						tabIndex={-1}
					>
						<div className="group flex flex-row items-center justify-center gap-1">
							<img className="block w-4 h-4" src="/images/icons/icon-boost.svg" alt={trans("Boost")} />
							<div className="group-hover:underline leading-none">{trans("Generate with AI")}</div>
						</div>
					</button>
				)}
			</div>
		</div>
	);
}
