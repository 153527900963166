import React, { useState, useEffect, useRef, useCallback, forwardRef, useMemo } from "react";
import useForwardRef from "@/hooks/useForwardRef";

import CorrectIcon from "@/images/icons/icon-correct-checkbox-multicolor.svg";
import WrongIcon from "@/images/icons/icon-wrong-checkbox-multicolor.svg";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import { v4 as uuidV4 } from "uuid";

export default forwardRef(function CheckboxCyan(
	{ children, className, checked, alignText = "right", onChange, ...props },
	ref
) {
	const id = useMemo(() => uuidV4(), []);

	return (
		<div className={tailwindCascade("flex flex-wrap gap-4", { "opacity-30": !!props.disabled }, className)}>
			<div className="w-6 h-6">
				{checked ? (
					<CorrectIcon className="fill-cyan absolute w-6 h-6 text-white" />
				) : (
					<WrongIcon className="fill-white absolute w-6 h-6 text-white" />
				)}
				<input
					ref={ref}
					id={id}
					type="checkbox"
					checked={checked}
					className="focus-visible:outline-white absolute w-6 h-6 rounded-md"
					onChange={onChange}
					{...props}
				/>
			</div>
			<label htmlFor={id}>{children}</label>
		</div>
	);
});
