import { useEffect, useRef } from "react";
import Button from "@/components/interactives/Button";
import Modal from "@/components/Modal";
import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";

export default function ConfirmDialog({
	className,
	text,
	confirmText,
	onConfirm,
	cancelText,
	onCancel,
	onDismiss,
	extraText,
	onExtra,
	confirmColor = "pink",
	extraColor = "green-light",
	layout = "horizontal",
}) {
	const buttonsContainerRef = useRef(null);

	// useEffect(() => {
	// 	// pre-select cancel button
	// 	buttonsContainerRef.current.lastChild.focus();
	// }, []);

	return (
		<Modal onCancel={onCancel ? onCancel : onDismiss}>
			<div className="bg-petrol-dark rounded-2xl flex flex-col items-center max-w-xl gap-8 p-12">
				<p
					className={tailwindCascade(
						"text-lg font-black text-center text-white whitespace-pre-wrap",
						className
					)}
				>
					{text}
				</p>
				<div
					className={tailwindCascade("flex md:flex-row gap-6", {
						"flex-col": layout === "vertical" || (onExtra && extraText),
						"flex-row": !(onExtra && extraText),
					})}
					ref={buttonsContainerRef}
				>
					{onConfirm && (
						<Button
							className="focus-visible:outline-white text-white"
							color={confirmColor}
							onClick={onConfirm}
						>
							{confirmText || trans("Confirm")}
						</Button>
					)}
					{onExtra && extraText && (
						<Button className="focus-visible:outline-white text-white" color={extraColor} onClick={onExtra}>
							{extraText}
						</Button>
					)}
					{onCancel && (
						<Button className="focus-visible:outline-white text-black" color="white" onClick={onCancel}>
							{cancelText || trans("Cancel")}
						</Button>
					)}
				</div>
			</div>
		</Modal>
	);
}
