import { tailwindCascade } from "@/helpers/tailwindCascade";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SlideEditorHeader from "./SlideEditorHeader";
import trans from "@/helpers/trans";
import CoverImage from "@/components/CoverImage";
import PenIcon from "@/images/icons/icon-edit.svg";
import TrashIcon from "@/images/icons/icon-trash.svg";
import { useImmer } from "use-immer";
import simplify from "simplify-js";
import { MAX_ANSWER_LENGTH, MAX_NUM_ANSWERS, SLIDE_TYPE_PINPOINT } from "@/app-constants.mjs";
import { clamp, cloneDeep, isEqual } from "lodash";
import RequiredFieldWarning from "./RequiredFieldWarning";
import colors from "@/colors";
import union from "@turf/union";

const PRECISION = 9; // Don't change once in production!
console.assert(PRECISION % 3 === 0, "precision must be an integer multiple of 3 for good base64 encodign of xy-pairs");

const POW2_PRECISION = 2 ** PRECISION;
const CHARS_PER_VERT = (2 * PRECISION) / 6;

const SEPARATOR = "~";
const MAX_NUM_VERTS = Math.floor(MAX_ANSWER_LENGTH[SLIDE_TYPE_PINPOINT] / CHARS_PER_VERT);
const SVG_WIDTH = 400;
const SVG_HEIGHT = 300;
const SVG_ASPECT = SVG_WIDTH / SVG_HEIGHT;

const MIN_AREA = 100;

export default function PinpointSlideEditor({
	className,
	mediaSize,
	newlyCreated,
	options,
	setOptions,
	slide,
	...props
}) {
	const [tool, setTool] = useState("pen");
	const [penPath, updatePenPath] = useImmer([]);

	// encode options as string to prevent re-render when unchanged
	const pathString = useMemo(() => getPinpointPathString(options), [options]);

	const polys = useMemo(
		() => getPinpointPolysFromString({ pathString, svgWidth: SVG_WIDTH, svgHeight: SVG_HEIGHT }),
		[pathString]
	);
	const paths = useMemo(() => getPinpointPathsFromPolys(polys), [polys]);

	const setOptionsRef = useRef(setOptions);
	useEffect(() => void (setOptionsRef.current = setOptions), [setOptions]);

	const svgRef = useRef(null);

	const getEventPos = useCallback((ev) => {
		if (svgRef.current) {
			const pt = svgRef.current.createSVGPoint();
			pt.x = ev.clientX;
			pt.y = ev.clientY;
			const svgP = pt.matrixTransform(svgRef.current.getScreenCTM().inverse());
			return { x: svgP.x, y: svgP.y };
		}
		return { x: 0, y: 0 };
	}, []);

	const beginDraw = useCallback(
		(ev) => {
			const { x, y } = getEventPos(ev);

			updatePenPath((draft) => {
				draft.splice(0);
				draft.push({ x, y });
			});
		},
		[getEventPos, updatePenPath]
	);

	const draw = useCallback(
		({ x, y }) => {
			updatePenPath((draft) => {
				draft.push({ x, y });
			});
		},
		[updatePenPath]
	);

	const onMouseMovePen = useCallback(
		(ev) => {
			ev.preventDefault();
			draw(getEventPos(ev));
		},
		[draw, getEventPos]
	);

	const onTouchMovePen = useCallback(
		(ev) => {
			ev.preventDefault();
			ev.stopPropagation();
			if (ev.touches.length === 1) {
				draw(getEventPos(ev.touches[0]));
			}
		},
		[draw, getEventPos]
	);

	const onMouseUpPen = useCallback(
		(ev) => {
			ev.preventDefault();
			document.removeEventListener("mousemove", onMouseMovePen);
			document.removeEventListener("mouseup", onMouseUpPen);
			document.removeEventListener("touchmove", onTouchMovePen, { passive: false });
			document.removeEventListener("touchend", onMouseUpPen);

			updatePenPath((draft) => {
				if (draft.length >= 3) {
					let path = cloneDeep(draft);
					draft.splice(0);

					let attempts = 0;
					const originalPath = cloneDeep(path);

					for (const point of originalPath) {
						point.x = clamp(point.x, 0, SVG_WIDTH);
						point.y = clamp(point.y, 0, SVG_HEIGHT);
					}

					while (attempts < 6 && path.length > MAX_NUM_VERTS) {
						path = simplify(originalPath, 2 ** attempts);
						attempts++;
					}

					if (attempts >= 6) {
						console.warn("not able to simplify polygon");
						return;
					}

					const area = polygonArea(path);
					if (Math.abs(area) < MIN_AREA) {
						return;
					} else if (area < 0) {
						path.reverse();
					}

					const arr = cloneDeep(options ?? []);
					arr.push({ text: encodePath(path, SVG_WIDTH, SVG_HEIGHT), isCorrect: true });
					setOptionsRef.current(arr);
				}
			});
		},
		[onMouseMovePen, onTouchMovePen, updatePenPath, options]
	);

	const onMouseDown = (ev) => {
		ev.preventDefault();

		if (tool === "pen") {
			document.addEventListener("mousemove", onMouseMovePen);
			document.addEventListener("mouseup", onMouseUpPen);

			beginDraw(ev);
		}
	};

	const onTouchStart = (ev) => {
		ev.stopPropagation();
		if (ev.touches.length === 1) {
			if (tool === "pen") {
				document.addEventListener("touchmove", onTouchMovePen, { passive: false }); // iOS 11 now defaults to passive: true
				document.addEventListener("touchend", onMouseUpPen);

				beginDraw(ev.touches[0]);
			}
		}
	};

	const [highlight, setHighlight] = useState(-1);

	const onClickPen = useCallback(() => void setTool((draft) => (draft !== "pen" ? "pen" : "")), []);
	const onClickDelete = useCallback(() => void setTool((draft) => (draft !== "delete" ? "delete" : "")), []);
	const onClickDeleteAll = useCallback(() => {
		if (window.confirm(trans("Are you sure?"))) {
			setOptions([]);
			if (tool === "delete") {
				setTool("");
			}
		}
	}, [setOptions, tool]);

	const hasSlideMedia = !!slide.media;
	useEffect(() => {
		if (!hasSlideMedia) {
			// Remove all polygons if media is removed
			// TODO: warn user before removing media
			setOptionsRef.current([]);
		}
		// TODO: transform polygons when media transform changes
	}, [hasSlideMedia]);

	const [oldSlide, setOldSlide] = useState(null);
	useEffect(() => {
		// Update polys when media transform changes
		if (
			oldSlide &&
			slide &&
			oldSlide.id === slide.id &&
			oldSlide.media &&
			slide.media &&
			!isEqual(oldSlide.media.transform, slide.media.transform) &&
			mediaSize?.isLoaded
		) {
			const t0 = { ...oldSlide.media.transform };
			const t1 = { ...slide.media.transform };

			const imageAspect = mediaSize.width / mediaSize.height;

			// Transform x/y to pixel space

			let cx, cy;

			if (imageAspect >= SVG_ASPECT) {
				// Wider than 4:3
				const q = imageAspect / SVG_ASPECT;
				cx = 0.01 * SVG_WIDTH * q;
				cy = 0.01 * SVG_HEIGHT;
			} else {
				// Taller than 4:3
				const q = SVG_ASPECT / imageAspect;
				cx = 0.01 * SVG_WIDTH;
				cy = 0.01 * SVG_HEIGHT * q;
			}

			t0.x *= cx;
			t0.y *= cy;

			t1.x *= cx;
			t1.y *= cy;

			const newPolys = cloneDeep(polys);
			for (const poly of newPolys) {
				for (const ring of poly) {
					for (const point of ring) {
						let { x, y } = point;

						x = x - SVG_WIDTH / 2;
						y = y - SVG_HEIGHT / 2;

						x = (x - t0.x) / t0.z;
						x = t1.z * x + t1.x;

						y = (y - t0.y) / t0.z;
						y = t1.z * y + t1.y;

						x = x + SVG_WIDTH / 2;
						y = y + SVG_HEIGHT / 2;

						point.x = clamp(x, 0, SVG_WIDTH);
						point.y = clamp(y, 0, SVG_HEIGHT);
					}
				}
			}

			const newOptions = newPolys
				.filter(([poly]) => polygonArea(poly) >= MIN_AREA)
				.map(([poly]) => ({
					text: encodePath(poly, SVG_WIDTH, SVG_HEIGHT),
					isCorrect: true,
				}));

			setOptionsRef.current(newOptions);
		}

		if (!isEqual(slide, oldSlide)) {
			setOldSlide(cloneDeep(slide));
		}
	}, [oldSlide, polys, slide, mediaSize]);

	const maxNumPathsReached = polys.length >= MAX_NUM_ANSWERS[SLIDE_TYPE_PINPOINT];
	const minNumPathsReached = polys.length === 0;

	useEffect(() => {
		if (maxNumPathsReached && tool === "pen") {
			setTool("");
		}
	}, [maxNumPathsReached, tool]);

	useEffect(() => {
		if (minNumPathsReached && tool === "delete") {
			setTool("");
		}
	}, [minNumPathsReached, tool]);

	return (
		<div className="flex flex-col w-full">
			<SlideEditorHeader
				title={
					<>
						{trans("Correct answer")}
						<RequiredFieldWarning
							hidden={newlyCreated || options.filter(({ text }) => text).length !== 0}
						/>
					</>
				}
				titleClassName="bg-green-light"
			/>
			<div className="bg-green-light rounded-b-xl rounded-tr-xl relative flex flex-col gap-1 p-1">
				{slide.media ? (
					<>
						<div className={tailwindCascade("pt-4/3 relative w-full h-0 overflow-hidden rounded-lg")}>
							<div className="grayscale absolute inset-0">
								<CoverImage media={slide.media} width={SVG_WIDTH} height={SVG_HEIGHT} />
							</div>
							<div className="bg-white-50 absolute inset-0" />
							<svg
								ref={svgRef}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
								fill="#ff08"
								stroke="black"
								strokeWidth={2}
								width="100%"
								height="100%"
								className="absolute inset-0"
								onMouseDown={onMouseDown}
								onTouchStart={onTouchStart}
								style={{
									cursor: tool === "pen" ? "url(/images/cursors/pen.svg) 3 23, auto" : undefined,
								}}
								// onMouseOver={() => void setHighlight(-1)
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								{paths.map((d, i) => (
									<path
										key={i}
										d={d}
										strokeWidth={2}
										stroke={
											tool === "delete" && highlight === i ? colors.PINK_DARK : colors.GREEN_DARK
										}
										fill={
											tool === "delete" && highlight === i
												? colors.PINK_LIGHT
												: colors.GREEN_LIGHT
										}
										fillOpacity={0.5}
										onMouseOver={() => void setHighlight(i)}
										onMouseOut={() => void setHighlight(-1)}
										onClick={
											tool === "delete" && highlight === i
												? () => {
														const newOptions = cloneDeep(options);
														newOptions.splice(i, 1);
														setOptionsRef.current(newOptions);
														setHighlight(-1);
												  }
												: undefined
										}
									/>
								))}
								{penPath.length > 0 && (
									<path
										d={toPathAttribute([penPath])}
										strokeWidth={2}
										stroke={colors.GREEN_DARK}
										fill={colors.GREEN_LIGHT}
										fillOpacity={0.3}
									/>
								)}
							</svg>
						</div>
						<div className="flex flex-row justify-start gap-1">
							<button
								className={tailwindCascade(
									"w-11 h-11 flex items-center justify-center p-0 bg-white-50 rounded-lg",
									{ "bg-white": tool === "pen", "opacity-50": maxNumPathsReached }
								)}
								onClick={onClickPen}
								disabled={maxNumPathsReached}
							>
								<PenIcon className="h-10" />
							</button>
							<button
								className={tailwindCascade(
									"w-11 h-11 flex items-center justify-center p-0 bg-white-50 rounded-lg",
									{ "bg-white": tool === "delete", "opacity-50": minNumPathsReached }
								)}
								onClick={onClickDelete}
								disabled={minNumPathsReached}
							>
								<TrashIcon className="h-10" />
							</button>
							<button
								className={tailwindCascade(
									"h-11 flex items-center justify-center p-0 px-2 bg-white-50 rounded-lg whitespace-nowrap font-bold",
									{ "opacity-50": minNumPathsReached }
								)}
								onClick={onClickDeleteAll}
								disabled={minNumPathsReached}
							>
								{trans("Clear all")}
							</button>
							{minNumPathsReached && (
								<div className="italic leading-tight text-white">
									{trans("Use the pen tool to encircle the correct answer in the image.")}
								</div>
							)}
							{maxNumPathsReached && (
								<div className="italic leading-tight text-white">
									{trans("A maximum of %s areas is allowed.", MAX_NUM_ANSWERS[SLIDE_TYPE_PINPOINT])}
								</div>
							)}
						</div>
					</>
				) : (
					<div className="px-2 py-4 italic text-white">
						{trans("Add media before you can specify the correct answer.")}
					</div>
				)}
			</div>
		</div>
	);
}
const BASE64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
console.assert(!BASE64.includes(SEPARATOR));

function encodePath(path, width, height) {
	const xScale = POW2_PRECISION / width;
	const yScale = POW2_PRECISION / height;

	return path
		.map(({ x, y }) => {
			x = clamp(Math.round(x * xScale), 0, POW2_PRECISION - 1);
			y = clamp(Math.round(y * yScale), 0, POW2_PRECISION - 1);

			const value = y * POW2_PRECISION + x;

			const arr = Array.from(
				{ length: CHARS_PER_VERT },
				(v, i) => Math.floor(value >> (6 * (CHARS_PER_VERT - 1 - i))) & 63
			);

			return arr.map((i) => BASE64[i]).join("");
		})
		.join("");
}
export function decodePath(str, width, height) {
	const xScale = width * 2 ** -PRECISION;
	const yScale = height * 2 ** -PRECISION;

	const n = Math.floor(str.length / CHARS_PER_VERT);
	const arr = new Array(n);
	for (let i = 0; i < n; i++) {
		const s = str.substring(i * CHARS_PER_VERT, i * CHARS_PER_VERT + CHARS_PER_VERT);

		const value = [...s].reduce(
			(sum, char, i) => sum + (1 << ((CHARS_PER_VERT - 1 - i) * 6)) * BASE64.indexOf(char),
			0
		);

		const x = (value & (POW2_PRECISION - 1)) * xScale;
		const y = ((value >> PRECISION) & (POW2_PRECISION - 1)) * yScale;
		arr[i] = { x, y };
	}
	return arr;
}
export function toPathAttribute(paths) {
	return paths
		.map((points) => (points.length ? "M " + points.map(({ x, y }) => `${x} ${y}`).join(" L ") : ""))
		.join(" ");
}

function polygonArea(vertices) {
	let total = 0;
	for (let i = 0; i < vertices.length; i++) {
		const j = i == vertices.length - 1 ? 0 : i + 1;
		const addX = vertices[i].x;
		const addY = vertices[j].y;
		const subX = vertices[j].x;
		const subY = vertices[i].y;
		total += addX * addY * 0.5;
		total -= subX * subY * 0.5;
	}
	return total;
}

export function inside(point, polygon) {
	let { x, y } = point;

	let inside = false;
	for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
		const xi = polygon[i].x;
		const yi = polygon[i].y;
		const xj = polygon[j].x;
		const yj = polygon[j].y;

		const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) {
			inside = !inside;
		}
	}

	return inside;
}

export function getPinpointPathString(answers) {
	return (answers ?? []).map(({ text }) => text).join(SEPARATOR);
}

function getPinpointPolysFromString({ pathString, merge, svgWidth, svgHeight }) {
	let paths = (pathString || "")
		.split(SEPARATOR)
		.map((str) => decodePath(str, svgWidth, svgHeight))
		.filter((path) => path.length > 2)
		.map((path) => [...path, { ...path[0] }]);

	if (paths.length === 0) {
		return [];
	}

	if (merge && paths.length > 1) {
		const features = paths.map((path) => ({
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [path.map(({ x, y }) => [x, y])],
			},
		}));

		let joined = true;
		while (joined) {
			joined = false;
			for (let i = 0; i < features.length; i++) {
				for (let j = features.length - 1; j > i; j--) {
					const merged = union(features[i], features[j]);
					if (merged.geometry.type === "Polygon") {
						// If it's "polygon" they are joint ("MultiPolygon" if disjoint)
						features[i] = merged;
						features.splice(j, 1);
						joined = true;
					}
				}
			}
		}

		paths = features.map((feature) => feature.geometry.coordinates.map((ring) => ring.map(([x, y]) => ({ x, y }))));
		return paths;
	}

	const res = paths.map((path) => [path.filter(Boolean)]);
	return res;
}

function getPinpointPathsFromPolys(polys) {
	return polys.map((poly) => toPathAttribute(poly));
}

export function getPinpointPathsFromString({ pathString, merge, svgWidth, svgHeight }) {
	const polys = getPinpointPolysFromString({ pathString, merge, svgWidth, svgHeight });
	return getPinpointPathsFromPolys(polys);
}
