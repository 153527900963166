import isString from "lodash/isString";

export function upperFirst(str, lang) {
	if (!isString(str)) {
		return str;
	}

	if (lang) {
		return str.slice(0, 1).toLocaleUpperCase(lang) + str.slice(1);
	} else {
		return str.slice(0, 1).toUpperCase() + str.slice(1);
	}
}
