import React from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

export default function RequiredFieldWarning({ hidden, title }) {
	return (
		<div
			className={tailwindCascade(
				"absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 z-1",
				"flex flex-col items-center justify-center",
				"bg-error w-5 h-5 text-sm font-bold rounded-full text-white",
				{ hidden: hidden }
			)}
			title={title ? title : trans("Required field")}
		>
			!
		</div>
	);
}
